<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thêm mới danh mục'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="w-25">
                <b-form-group id="input-group-3" label="Danh mục cha" label-for="input-3">
                  <b-form-select
                    id="input-1"
                    v-model="selectedParent"
                    :options="listCate"
                    required
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled>-- Chọn danh mục cha --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="w-25">
                <b-form-group id="input-group-1" label="Tên" label-for="input-1">
                  <b-form-input
                    id="input-2"
                    type="name"
                    v-model="$v.form.name.$model"
                    :state="validateState('name')"
                    required
                    placeholder="Nhập tên"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="w-25">
                <b-form-group id="input-group-1" label="Mã" label-for="input-1">
                  <b-form-input
                    id="input-3"
                    type="name"
                    v-model="$v.form.code.$model"
                    :state="validateState('code')"
                    required
                    placeholder="Nhập mã"
                  ></b-form-input>
                </b-form-group>
              </div>

              <button class="btn btn-primary" @click="createCategory">Lưu</button>
              <router-link :to="'/categories'">
                <b-button class="btn btn-primary ml-2" type="reset" variant="danger">Hủy</b-button>
              </router-link>
            </v-form>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}
</style>
<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import { minLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      listCate: [],
      selectedParent: null,
      form: {
        name: "",
        code: ""
      }
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      code: {
        required,
        minLength: minLength(3)
      }
    }
  },
  components: {
    KTCodePreview
  },
  created() {
    this.getListCate();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh mục sản phẩm", route: "/categories" },
      { title: "Danh sách danh mục", route: "/categories" },
      { title: "Thêm mới danh mục" }
    ]);
    this.selectedParent = this.$route.query.id;
    if (this.selectedParent === undefined) {
      this.selectedParent = null;
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    reset() {
      this.$refs.form.reset();
    },
    getListCate: function() {
      this.listCate = [];
      let param = {
        page: 1,
        limit: 10,
        name: "",
        code: ""
      };
      let paramQuery = {
        params: param
      };
      ApiService.setHeader();
      ApiService.query("category", paramQuery).then(({ data }) => {
        this.numberOfPage = data.data.total_page;
        data.data.list_caterogy.forEach(element => {
          let item = {
            id: element.id,
            name: element.name
          };
          this.listCate.push(item);
        });
      });
    },
    createCategory: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const name = this.$v.form.name.$model;
      const code = this.$v.form.code.$model;
      let data = {
        code: code,
        name: name,
        parentId: this.selectedParent
      };
      ApiService.setHeader();
      ApiService.post("category/create", data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess("Thêm mới danh mục thành công!");
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: "success",
        solid: true
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: "danger",
        solid: true
      });
    }
  }
};
</script>
